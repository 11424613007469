<div class="w-full flex flex-column">
  <zxing-scanner
    *ngIf="!isBusy"
    (scanSuccess)="handleQrCodeResult($event)"
    [device]="currentDevice"
    [formats]="allowedFormats"
  >
  </zxing-scanner>
  <div class="w-full flex justify-content-between align-items-center p-4">
    <div></div>
    <div class="flex align-items-center">
      <p-progressSpinner
        styleClass="w-4rem h-4rem"
        strokeWidth="8"
        fill="var(--surface-ground)"
        animationDuration=".5s"
      />
      <p class="ml-2 m-0">{{ status }}</p>
    </div>
    <p-button size="small" label="Close" (click)="closeDialog()"></p-button>
  </div>
</div>
