import { Component, Input, OnInit } from '@angular/core';
import { RouteResolverService } from '../../service/route-resolver.service';
import { LayoutService } from '../../service/app.layout.service';
import {
  RouteDescription,
  featurePriorities,
} from 'src/app/modules/core/constants/routeDescriptions';

@Component({
  selector: 'app-bottom-nav',
  templateUrl: './bottom-nav.component.html',
  styleUrls: ['./bottom-nav.component.scss'],
})
export class BottomNavComponent implements OnInit {
  @Input() rewardoFeatures!: RouteDescription[];
  fList: RouteDescription[] = [];

  constructor(
    public route: RouteResolverService,
    public layoutService: LayoutService
  ) {}

  ngOnInit(): void {
    for (let index = 0; index < featurePriorities.length; index++) {
      const element = featurePriorities[index];
      if (this.fList.length == 4) {
        break;
      }
      if (this.rewardoFeatures.find((tf) => tf.label == element) != null) {
        this.fList = [
          ...this.fList,
          this.rewardoFeatures.find((tf) => tf.label == element)!,
        ];
      }
    }
  }

  isSettings(url: string): boolean {
    var urlSegments = this.fList.map((u) => this.getRouteSegment(u));
    if (urlSegments.find((s) => s == url) == null) {
      return true;
    }
    return false;
  }
  onSettingsClick() {
    this.layoutService.showProfileSidebar();
  }
  getRouteSegment(route: RouteDescription) {
    return route.routerLink[0].split('/')[1];
  }
}
