import { Component, OnDestroy, OnInit } from '@angular/core';
import { MenuService } from './layout/app.menu.service';
import { LayoutService } from './layout/service/app.layout.service';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { filter, map } from 'rxjs';
import { BaseComponent } from './modules/core/components/base/base.component';
import { AuthenticationService } from './modules/authentication/services/authentication.service';
import { BroadcasterService } from './modules/core/services/broadcaster.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends BaseComponent implements OnInit, OnDestroy {
  title = 'rewardo';
  modalVersion!: boolean;

  constructor(
    broadcastService: BroadcasterService,
    authService: AuthenticationService,
    private swUpdate: SwUpdate,
    public layoutService: LayoutService,
    public menuService: MenuService
  ) {
    super(broadcastService, authService);
    this.layoutService.config.menuMode = 'slim';
    this.modalVersion = false;
    this.swUpdate.available.subscribe(() => {
      this.modalVersion = true;
    });
  }
  override ngOnInit(): void {
    super.ngOnInit();
    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates.pipe(
        filter(
          (evt: any): evt is VersionReadyEvent => evt.type === 'VERSION_READY'
        ),
        map((evt: any) => {
          console.info(
            `currentVersion=[${evt.currentVersion} | latestVersion=[${evt.latestVersion}]`
          );
          this.modalVersion = true;
        })
      );
    }
  }
  public updateVersion(): void {
    this.modalVersion = false;
    window.location.reload();
  }
  override ngOnDestroy(): void {
    this.broadcastService.unsubscribe();
  }
}
