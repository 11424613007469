import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppLayoutComponent } from './layout/app.layout.component';
import { AuthenticationGuard } from './modules/authentication/guards/authentication.guard';
import { NoTenantAuth } from './modules/authentication/guards/no-tenant-auth.guard';
import { SettingsComponent } from './modules/settings/components/settings/settings.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'tenant',
    pathMatch: 'full',
  },
  {
    path: 'settings',
    canActivateChild: [NoTenantAuth],
    component: SettingsComponent,
    data: { breadcrumb: 'Settings' },
    loadChildren: () =>
      import('./modules/settings/settings.module').then(
        (m) => m.SettingsModule
      ),
  },
  {
    path: 'users',
    component: AppLayoutComponent,
    canActivateChild: [AuthenticationGuard],
    data: { breadcrumb: 'Users' },
    loadChildren: () =>
      import('./modules/users/users.module').then((m) => m.UsersModule),
  },
  {
    path: 'tenant',
    component: AppLayoutComponent,
    canActivateChild: [AuthenticationGuard],
    data: { breadcrumb: 'Tenant' },
    loadChildren: () =>
      import('./modules/tenants/tenants.module').then((m) => m.TenantsModule),
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./modules/authentication/authentication.module').then(
        (m) => m.AuthenticationModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
