<ngx-loading-bar
  [includeSpinner]="false"
  height="5px"
  color="#1C1C1C"
></ngx-loading-bar>
<p-toast></p-toast>
<router-outlet></router-outlet>
<p-sidebar
  id="update-bar"
  [(visible)]="modalVersion"
  position="bottom"
  [showCloseIcon]="false"
  [dismissible]="false"
>
  <div class="flex justify-content-between">
    <p>You have a new update!</p>
    <p-button
      (click)="updateVersion()"
      styleClass="p-button-sm p-button-success"
      class="p-button-success"
      >Update</p-button
    >
  </div>
</p-sidebar>
