<div
  class="coupon-container"
  *ngIf="coupon"
  style="position: relative; overflow: hidden"
  [ngStyle]="{
    'background-color':
      !coupon.backgroundImageUrl && coupon.backgroundColorHex
        ? coupon.backgroundColorHex
        : 'white'
  }"
>
  <!-- Background Image as img tag -->
  <img
    *ngIf="coupon.backgroundImageUrl"
    [src]="coupon.backgroundImageUrl"
    alt="Coupon Background Image"
    class="background-image"
  />

  <div class="coupon-content" style="position: relative; z-index: 1">
    <!-- Left Section with Icon and Discount -->
    <div class="left-section">
      <div class="discount-icon">
        <img [src]="coupon.couponIconMediaUrl" alt="Coupon Icon" />
      </div>
      <div class="discount-text m-0">
        <h2
          class="m-0 text-lg font-bold"
          [style]="{ color: coupon.discountTextColorHex }"
        >
          {{ coupon.iconText }}
        </h2>
      </div>
    </div>

    <div
      class="p-0 xl:p-2 h-full border-y-none border-left-none border-noround-right border-1 border-red-500 border-dashed"
    ></div>

    <!-- Right Section with Details -->
    <div class="right-section flex flex-column justify-content-between h-full">
      <div class="flex flex-column">
        <h3
          class="font-bold text-lg"
          [style]="{ color: coupon.titleTextColorHex }"
        >
          {{ coupon.title }}
        </h3>
        <p
          class="text-md"
          [style]="{ color: coupon.descriptionTextColorHex }"
        >
          {{ coupon.description | limitStringLength : 190 }}
        </p>
      </div>
      <p
        class="mb-0 font-semibold"
        [style]="{ color: coupon.expirationTextColorHex }"
      >
        {{ coupon.expirationDate | couponExpireOrNever }}
      </p>
    </div>
  </div>
</div>
