<div  *ngIf="details" class="w-full flex flex-column pt-4">
    <h1 class="m-0 text-3xl font-bold">
      {{ details.firstName }} {{ details.lastName }}
    </h1>
    <p>ID : {{ details.userName }}</p>
    <div class="w-full mt-4">
        <app-coupon [coupon]="details.customerCoupon!.coupon"></app-coupon>
    </div>
    <div class="mt-4 flex justify-content-end align-items-center gap-2">
      <p-button [disabled]="isLoading" [outlined]="true" size="small" label="Close" (click)="closeDialog()"></p-button>
      <p-button [disabled]="isLoading" severity="success" size="small" label="Use" (click)="useCoupon()"></p-button>
    </div>
  </div>
  