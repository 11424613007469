import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppLayoutModule } from './layout/app.layout.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HTTPReqResInterceptor } from './modules/core/services/http.interceptor';
import { CoreModule } from './modules/core/core.module';
import { ITokens } from './modules/core/constants/ITokens';
import { environment } from 'src/environments/environment';
import { SharedModule } from './modules/shared/shared.module';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { MessageService } from 'primeng/api';

import { ServiceWorkerModule } from '@angular/service-worker';
import { SidebarModule } from 'primeng/sidebar';
import { MessagesModule } from 'primeng/messages';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    LoadingBarHttpClientModule,
    LoadingBarModule,
    BrowserAnimationsModule,
    CoreModule,
    SharedModule,
    AppLayoutModule,
    AppRoutingModule,
    SidebarModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    MessagesModule
  ],
  providers: [
    { provide: ITokens.API_URL, useValue: environment.apiUrl },
    { provide: ITokens.Server_URL, useValue: environment.api },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HTTPReqResInterceptor,
      multi: true,
    },
    MessageService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
